$tiny: 'max-width: 374px'; // below modern iphone
$small: 'max-width: 575px';
$tablet: 'min-width: 768px'; // ipad and up
$tablet-wide: 'min-width: 992px';
$laptop: 'min-width: 1024px';

#r1Calculator {
    font-size: 1.4rem;
    font-style: normal;
    font-weight: normal;
    line-height: 1.2;
    margin: -$calcTabHeight auto 0;

    @media ($small) {
        margin: 0 auto;
    }

    * {
        box-sizing: border-box;
    }

    .clearfix::before,
    .clearfix::after {
        clear: both;
        content: '';
        display: table;
    }

    sup,
    sub {
        line-height: 0;
    }
}

#r1Tabs {
    ul {
        list-style: none;
        margin: 0;
        padding: 0;
        width: 100%;

        @media ($small) {
            display: flex;
        }
    }

    li {
        background: $mediumBrown;
        color: $lighterGray;
        cursor: pointer;
        display: block;
        float: left;
        height: $calcTabHeight;
        margin: 0;
        padding: 1.5rem 3rem;

        &:first-of-type {
            border-right: 1px solid $lightestGray;
        }

        &:last-of-type {
            border-left: 1px solid $lightestGray;
        }

        &.active {
            background: $darkBrown;
            background: url(/assets/website/images/backgrounds/paper.jpg);
            background-size: 25%;
            color: $white;
        }

        @media ($small) {
            flex: 1 1 50%;
            font-size: 1.2rem;
            padding: 1.5rem 0;
            text-align: center;
        }
    }
}

#r1Panels {
    background: $darkBrown;
    background: url(/assets/website/images/backgrounds/paper.jpg);
    background-size: 25%;
    overflow: hidden;
    padding: 3rem 2.2rem .6rem;

    @media ($tablet) {
        padding: 3rem 3rem 4.5rem;
    }

    @media ($tablet-wide) {
        padding: 4.5rem;
    }

    .r1-panel {
        display: none;

        &.active {
            display: block;
        }
    }

    h3 {
        color: $white;
        font-family: $serifFont;
        font-size: 2.8rem;
        font-style: italic;
        font-weight: $normalFontWeight;
        line-height: 1em;
        margin: 0 0 4rem;

        @media ($small) {
            font-size: 2.2rem;
        }

        @media ($tablet) {
            margin-bottom: 4.5rem;
        }
    }

    h4 {
        color: $white;
        font-family: $baseFontCondensed;
        font-size: 1.9rem;
        font-weight: $boldFontWeight;
        line-height: 1em;
        margin: 0 0 2rem;
        text-transform: uppercase;
    }

    .r1-inputs-column {
        border-bottom: 1px solid;
        margin-bottom: 4rem;
        padding-bottom: 1.7rem;

        @media ($tablet) {
            border-bottom: none;
            border-right: 1px solid;
            float: left;
            margin-bottom: 0;
            padding-bottom: 0;
            padding-right: 3rem;
            width: 50%;
        }

        @media ($tablet-wide) {
            padding-right: 4.5rem;
        }
    }

    .r1-results-column {
        margin-bottom: 1.5rem;

        @media ($tablet) {
            float: left;
            margin-bottom: 0;
            padding-left: 3rem;
            width: 50%;
        }

        @media ($tablet-wide) {
            padding-left: 4.5rem;
        }
    }

    .r1-panel__inputs {
        @media ($laptop) {
            width: 40%;
        }
    }

    .r1-panel__results {
        @media ($laptop) {
            width: 60%;
        }
    }

    .r1-input {
        margin-bottom: 2.5rem;

        label {
            color: $white;
            display: block;
            font-size: 1.3rem;
            font-weight: $semiBoldFontWeight;
            margin-bottom: 1rem;
        }

        input,
        select {
            background: $gray;
            border: none;
            border-left: 1px solid $darkerGray;
            border-top: 1px solid $darkerGray;
            color: $white;
            font-family: inherit;
            font-size: 1.3rem;
            height: 4rem;
            padding: 0 1.5rem;
            width: 100%;

            &::placeholder {
                color: $lighterGray;
                font-style: italic;
            }
        }

        &:last-of-type {
            @media ($tablet) {
                margin-bottom: 0;
            }
        }

        .r1-input-select {
            appearance: none;
            background-image: url('/assets/website/images/temporary/calc-dropdown.png');
            background-position: 95%;
            background-repeat: no-repeat;
            background-size: 10px;
        }
    }

    .r1-results-number {
        color: $cream;
        font-family: $industryFont;
        font-size: 4.4rem;
        font-weight: $semiBoldFontWeight;
        letter-spacing: 0;
        margin: -2rem 0 3rem;

        @media ($tablet) {
            border-bottom: 1px solid $darkGray;
            line-height: 1.2em;
            margin: -1rem 0 0;
            padding-bottom: 3.4rem;
        }

        @media ($laptop) {
            padding-bottom: 5rem;
        }

        &::before {
            content: '$';
            line-height: 0;
        }
    }

    .r1-results-column__total {
        @media ($tablet) {
            margin-top: 3.5rem;
        }

        @media ($laptop) {
            margin-top: 6rem;
        }
    }

    .r1-principal-label,
    .r1-interest-label,
    .r1-fees-label {
        color: $cream;
        font-size: 1.3rem;

        span {
            color: $white;
            display: block;
            font-size: 1.6rem;

            &::before {
                content: '$';
                line-height: 0;
            }
        }
    }

    .r1-principal-label {
        float: left;
    }

    .r1-interest-label {
        text-align: right;
        transition: margin 1s;
    }

    .r1-fees-label {
        transition: margin 1s;
    }

    .r1-results-bar {
        background: rgba(255, 255, 255, 0.2);
        border-radius: 1rem;
        clear: both;
        height: 2rem;
        margin: 1.5rem 0;
        overflow: hidden;
        width: 100%;
    }

    .r1-total-principal,
    .r1-total-fees {
        float: left;
        height: 100%;
        transition: width 1s;
        width: 0;
    }

    .r1-total-principal {
        background: $mustard;
    }

    .r1-total-fees {
        background: $lightMustard;
    }
}

.r1-results-graph {
    margin-bottom: 5rem;

    @media ($laptop) {
        margin-bottom: 3rem;
    }
}

.button.r1-panel__button {
    margin-bottom: 1.6rem;
    text-transform: capitalize;
    width: 100%;

    @media ($tablet) {
        margin-bottom: 2.4rem;
    }

    @media ($laptop) {
        margin-bottom: 0;
    }
}

.button-wrapper {
    @media ($laptop) {
        display: flex;
    }
}

#r1Disclaimer {
    background: $darkBrown;
    background: url(/assets/website/images/backgrounds/paper.jpg);
    background-size: 25%;
    padding: 0 2.2rem 3rem;

    @media ($tablet) {
        padding: 0 3rem 4.5rem;
    }

    @media ($tablet-wide) {
        padding: 0 4.5rem 4.5rem;
    }

    .disclaimer__inner {
        border-top: 1px solid;
        padding-top: 4rem;
    }

    p {
        color: $white;
        font-size: 1rem;
        line-height: 1.2;
        margin: 0;
    }

    .r1-attribution {
        margin-top: 3.5rem;

        @media ($tablet) {
            margin-top: 4.5rem;
        }

        @media ($laptop) {
            margin-top: 5.5rem;
        }

        &__logo-wrapper {
            display: block;
            margin-top: 1.5rem;
        }

        img {
            height: auto;
            vertical-align: middle;
            width: 27rem;
        }
    }
}
