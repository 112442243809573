/* stylelint-disable */

@import 'mixins';

html { font-family: '1'; }
@include mobileLandscape { html { font-family: '2'; } }

@include tablet { html { font-family: '3'; } }

@include desktop { html { font-family: '4'; } }

@include desktop2 { html { font-family: '5'; } }

@include desktop3 { html { font-family: '6'; } }

@include desktop4 { html { font-family: '7'; } }
