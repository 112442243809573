// Colors

$gunMetal: #5c5750;
$olive: #47432e;
$oliveHover: #6c643c;
$oliveActive: #29271c;
$lightBrown: #72685a;
$brown: #363427;
$mediumBrown: #2a2724;
$darkBrown: #110e0a;
$lightMustard: #998556;
$mustard: #886d2f;
$orange: #b98b30;
$orangeButton: #ad4610;
$orangeButtonHover: #c24d0d;
$orangeButtonActive: #973c0b;
$gold: #574624;
$goldHover: $mustard;
$goldActive: #3b2f18;
$rust: #7b2a00;
$cream: #d3ccc2;
$greenTea: #817a48;

//$primary: $mustard;
//$secondary: $olive;
//$info: #4a6895;
//$success: #869e21;
//$warning: #d4b31c;
$error: $rust;

// Grayscale

$white: #fff;
$lightestGray: darken(#fff, 4%);
$lighterGray: darken(#fff, 10%);
$lightGray: darken(#fff, 25%);
$gray: darken(#fff, 35%);
$darkGray: darken(#fff, 55%);
$darkerGray: darken(#fff, 65%);
$darkestGray: darken(#fff, 75%);
$black: #000;

$transparentWhite: rgba($white, .2);

@import '~@whitetail/buckshot/styles/variables.scss';

// Layout

$minWidth: false;
$maxWidth: 1600px;
$headerHeight: 12.3rem;
$headerHeightMobile: 6.8rem;
$calcTabHeight: 5.3rem;

// Listing Status

$statusForSale: #78cb11;
$statusNew: #40affd;
$statusSold: #ef2101;
$statusPending: #ffef0c;
$statusReduced: #f7931f;
$statusAuction: #0000fe;

$dimStatusForSale: darken($statusForSale, 10%);
$dimStatusNew: darken($statusNew, 10%);
$dimStatusSold: darken($statusSold, 10%);
$dimStatusPending: darken($statusPending, 10%);
$dimStatusReduced: darken($statusReduced, 10%);
$dimStatusAuction: darken($statusAuction, 10%);

// Grayscale

$lightestGray: #eee;
$lighterGray: #ccc;
$lightGray: #7d7d7d;
$gray: #46433f;
$darkGray: #333;
$darkerGray: #2e2c29;
$darkestGray: #171717;

// Links

$linkColor: $gold;
$linkColorHover: $gold;
$linkColorActive: $goldActive;

$linkDecoration: none;
$linkDecorationHover: underline;

// Base

// Root sizing

$rootFontSize: 62.5%;

$baseColor: $darkerGray;
$baseFont: 'Acumin', Helvetica, Arial, sans-serif;
$baseFontSize: 1.6rem;
$baseFontWeight: normal;
$baseLineHeight: 1em;

$industryFont: 'Industry', $baseFont;
$baseFontCondensed: 'Acumin-Pro-Condensed', $baseFont;
$serifFont: 'Marcia', serif;

$bodyBackground: $lightestGray;

// Icons
$iconFont: 'icomoon';

$iconSearch: '\e900';
$iconFacebook: '\e901';
$iconInstagram: '\e903';
$iconCrosshair: '\e90c';
$iconDropdown: '\e904';
$iconCheck: '\e905';
$iconLess: '\e906';
$iconMore: '\e907';
$iconMenu: '\e908';
$iconFullScreen: '\e909';
$iconLinkedin: '\e90a';
$iconClose: '\e90b';
$iconHeartFull: '\e910';
$iconHeart: '\e911';
$iconTwitter: '\eef5';
$iconYoutube: '\eefc';
$iconFile: '\e90f';
$iconPin: '\ea37';
$iconShare: '\e912';
$iconLink: '\e90e';
$iconPrinter: '\e90d';
$iconSpinner: '\eb29';
$iconUsa: '\e913';

// Miscellaneous

$smallFontSize: .8em;

$selectionColor: $lightestGray;
$selectionBackground: $greenTea;

$normalFontWeight: 400;
$mediumFontWeight: 500;
$semiBoldFontWeight: 600;
$demiFontWeight: $semiBoldFontWeight;
$boldFontWeight: 700;

$markColor: $baseColor;
$markBackground: $mustard;

$defaultRadius: 4px;
$defaultOpacity: .2;
$defaultDuration: .2s;
$defaultTiming: ease-in-out;

$abbrUnderline: dotted;

$lighterGrayBorder: 1px solid $lighterGray;
$grayBorder: 1px solid $gray;
$darkerGrayBorder: 1px solid $darkerGray;

// Paragraphs

$paragraphColor: $baseColor;
$paragraphFontWeight: $baseFontWeight;
$paragraphLineHeight: 1.7em;

$paragraphMarginBottom: 1.6rem;

// Headings

$headingColor: $baseColor;
$headingFont: $baseFont;
$headingFontWeight: $boldFontWeight;
$headingLineHeight: 1.3em;

$headingMarginBottom: 2rem;

$h1: 3.5rem;
$h2: 2.4rem;
$h3: 2.2rem;
$h4: 2rem;
$h5: 1.8rem;
$h6: 1.6rem;

// Images

$imageLeftClass: img-left;
$imageRightClass: img-right;
$imageMarginBottom: 0;

// Figures

$figurePadding: 0;

// Fonts

$fontPath: '../../website/fonts/';

$iconFont: icomoon;

$woff2Enabled: true;

// Forms

// Inputs

$inputColor: $white;
$inputPlaceholderColor: $lighterGray;

// Buttons

$buttonColor: $white;
$buttonFont: $industryFont;
$buttonFontSize: 1.4rem;
$buttonFontWeight: $boldFontWeight;

$buttonPaddingHorizontal: 1.9rem;
$buttonPaddingVertical: 1.3rem 1.6rem;

$buttonMarginBottom: false;

$buttonRounded: $defaultRadius;

$buttonTransitionEnabled: true;
$buttonTransitionProperty: background-color;
$buttonTransitionDuration: $defaultDuration;

$buttonActiveTransitionEnabled: true;
$buttonActiveTransitionProperty: background-color;
$buttonActiveTransitionDuration: $defaultDuration;

$buttonBackground: $olive;
$buttonBackgroundHover: $oliveHover;
$buttonBackgroundActive: $oliveActive;

$buttonBorderColor: false;

$tableStyled: true;
$tableBordered: false;
$tableStriped: false;

$tableFontSize: $baseFontSize;

$tableBorderedClassModifier: -bordered;
$tableStripedClassModifier: -striped;

// Table Cells

$tableCellLineHeight: 1.4em;
$tableCellBorderColor: $lighterGray;
$tableCellPaddingHorizontal: 1.6rem;
$tableCellPaddingVertical: 3rem;

// Print

$printPageMargin: 2cm .5cm;

@import '~@whitetail/buckshot/styles/mixins';
@import '../../styles/responsive/mixins';

@mixin fullHeight {
    height: calc(#{var(--viewport)} + #{env(safe-area-inset-bottom)});
    height: var(--viewport);
}

@mixin saveButton {
    align-items: center;
    display: flex;
    justify-content: center;

    &::before {
        @include icon($iconHeart, 1.6rem);

        bottom: .1rem;
        color: $white;
        margin-right: .9rem;
        position: relative;
    }

    &.-is-saved {
        &::before {
            animation: save .3s ease-in-out forwards;
        }
    }

    @keyframes save {
        0% {
            color: $white;
            content: $iconHeart;
            transform: scale(1);
        }

        50% {
            color: $white;
            content: $iconHeartFull;
            transform: scale(0);
        }

        100% {
            color: $statusSold;
            content: $iconHeartFull;
            transform: scale(1);
        }
    }
}

@mixin sectionHeading {
    @include font($industryFont, 2.1rem, $boldFontWeight, false, false, .1rem);

    text-transform: uppercase;
}

/**
 * Add a square or circular "dot" after a list item
 */
@mixin dot($color: $lightBrown, $shape: 'square', $position: 'after', $size: .3rem) {
    align-items: center;
    display: inline-flex;
    position: relative;

    @if ($position == 'after') {
        &::after {
            @include absolute($right: 0);
            @include size($size);

            background-color: $color;
            content: '';

            @if ($shape == 'circle') {
                border-radius: 50%;
            }
        }
    }

    @else {
        &::before {
            @include absolute($left: 0);
            @include size($size);

            background-color: $color;
            content: '';

            @if ($shape == 'circle') {
                border-radius: 50%;
            }
        }
    }
}

/**
 * Create an inset outline around the section
 */
@mixin sectionBorder($borderColor: $darkerGray, $offset: -1.8rem) {
    outline: 1px solid  $borderColor;
    outline-offset: -.7rem;

    @include desktop {
        outline-offset: $offset;
    }
}

/**
 * Add a topographical map to the top left and bottom right of the section
 */
@mixin topoBackground() {
    background:
        url('/assets/website/images/backgrounds/topo-top-left.svg') no-repeat top left,
        url('/assets/website/images/backgrounds/topo-bottom-right.svg') no-repeat bottom right;
    background-size: 75%;

    @include mobileLandscape {
        background-size: 37%;

        &::after {
            background-size: 37%;
        }
    }

    @include desktop2 {
        background-size: auto;

        &::after {
            background-size: auto;
        }
    }
}

@mixin gridBackground() {
    background: url('/assets/website/images/backgrounds/grid.png');
}

@mixin paperBackground() {
    background: url('/assets/website/images/backgrounds/paper.jpg');
    background-size: 25%;
}

@mixin imageFilter($on: 'true') {
    @if ($on) {
        filter: sepia(100%) saturate(20%) brightness(0.8) contrast(1.03);
    }

    @else {
        filter: sepia(0%) saturate(100%) brightness(1) contrast(1);
    }
}

@mixin woodgrainBackground($color: 'light') {
    background-size: 25%;

    @if ($color == 'dark') {
        background: $darkBrown url('/assets/website/images/backgrounds/wood-dark.jpg');
    }

    @else {
        background: $cream url('/assets/website/images/backgrounds/wood-light.jpg');
    }
}

@mixin thickBorder() {
    border: 9px solid rgba($black, .14);
    border-radius: 5px;
}

@mixin searchButton() {
    @include flexCenter;

    font-size: 0;
    min-width: 0;
    padding: 1.4rem .9rem;
    width: auto;

    &::after {
        @include icon($iconSearch, 2.8rem);
    }
}

@mixin uiSwitch($size: 'normal') {
    @include buttonColor($gray, $gunMetal, $darkerGray);
    @include flexCenter;
    @include size(3.1rem);

    border-radius: 50%;
    cursor: pointer;

    &::after {
        @include icon($iconMenu, 2rem);
        @include relative($top: -.1rem, $left: .1rem);

        color: $lightGray;
        transform: rotate(90deg);
    }

    @if ($size == 'small') {
        @include tablet {
            @include size(2.5rem);

            &::after {
                font-size: 1.5rem;
            }
        }
    }
}

@mixin fullCardLink() {
    &::after {
        @include absolute($top: 0, $left: 0);
        @include size(100%);

        content: '';
        width: 100%;
        z-index: 1;
    }

    &:hover {
        text-decoration: none;
    }

    &:focus {
        outline: none;
        text-decoration: underline;
    }
}

@mixin selection($side: 'bottom', $width: 0, $left: 0) {
    background-color: $mustard;
    bottom: 0;
    content: '';
    display: block;
    height: .4rem;
    left: $left;
    position: absolute;
    transition: left $defaultDuration, width $defaultDuration;
    width: $width;

    &::before {
        @include absoluteCenter(horizontal);
        @include size(0);

        border-bottom: 5px solid $mustard;
        border-left: 5px solid transparent;
        border-right: 5px solid transparent;
        content: '';
        top: -100%;
    }

    @if ($side == 'top') {
        bottom: unset;
        top: 0;

        &::before {
            border-bottom: none;
            border-top: 5px solid $mustard;
            bottom: -100%;
            top: unset;
        }
    }
}

@mixin flickityButton() {
    @include size(4rem, 100%);

    background: transparent;
    opacity: 0;
    right: 0;
    top: 0;
    transform: translateY(0);
    transition: opacity $defaultDuration ease-in-out;
    z-index: 9;

    .flickity-button-icon {
        fill: $white;
        top: 50%;
        transform: translateY(-50%);
        transition: transform $defaultDuration;
        z-index: 1;
    }

    &::after {
        @include size(140%, 100%);

        background-image: linear-gradient(to right, rgba($black, 0) 0%, $black 100%);
        content: '';
        opacity: .33;
        position: absolute;
        right: 0;
        top: 0;
        transition: opacity $defaultDuration;
    }

    &.previous {
        left: 0;
        right: unset;

        &::after {
            background-image: linear-gradient(to left, rgba($black, 0) 0%, $black 100%);
            left: 0;
            right: unset;
        }
    }

    &:active {
        opacity: 1;

        .flickity-button-icon {
            opacity: .6;
        }
    }

    &:focus,
    &:not([disabled]):hover {
        box-shadow: none;

        &::after {
            opacity: .65;
        }

        .flickity-button-icon {
            transform: translateY(-50%) scale(1.25);
        }
    }
}

/**
 * Vertical and horizontal centering with flexbox
 */
@mixin flexCenter($inline: false) {
    align-items: center;
    display: if($inline, inline-flex, flex);
    justify-content: center;
}

/**
 * Vertical and/or horizontal centering using absolute positioning
 */
@mixin absoluteCenter($direction: both, $top: unset, $right: unset, $bottom: unset, $left: unset) {
    @include absolute($top, $right, $bottom, $left);

    @if ($direction == horizontal) {
        left: 50%;
        transform: translateX(-50%);
    }

    @if ($direction == vertical) {
        top: 50%;
        transform: translateY(-50%);
    }

    @if ($direction == both) {
        left: 50%;
        top: 50%;
        transform: translateX(-50%) translateY(-50%);
    }
}

@mixin buttonReset {
    appearance: none;
    background-color: transparent;
    border: none;
    cursor: pointer;

    &:focus {
        outline: 1px solid $orange;
    }
}

@mixin buttonColor($color: $olive, $hover: $oliveHover, $active: $oliveActive, $shadow: true) {
    background-color: $color;
    transition: all $defaultDuration;

    @if $shadow {
        box-shadow: 0 .2rem 0 0 rgba(0, 0, 0, .5), inset 0 .1rem 0 0 lighten($color, 18%);
    }

    &:disabled,
    &.-is-disabled {
        background-color: $color;
        opacity: .75;
    }

    &:active {
        background-color: $active;

        @if $shadow {
            box-shadow: 0 -.2rem 0 0 rgba(0, 0, 0, .5), inset 0 -.1rem 0 0 lighten($color, 18%);

            @if ($hover == $white) {
                box-shadow: 0 .2rem 0 0 rgba(0, 0, 0, .5), inset 0 .1rem 0 0 lighten($gray, 18%);
            }
        }
    }

    &:hover,
    &:focus {
        &:not(:disabled),
        &:not(.-is-disabled) {
            background-color: $hover;
            outline: none;

            @if $shadow {
                box-shadow: 0 .4rem .3rem 0 rgba(0, 0, 0, .5), inset 0 .2rem .2rem 0 lighten($color, 18%);

                @if ($hover == $white) {
                    box-shadow: 0 .2rem 0 0 rgba(0, 0, 0, .5), inset 0 .1rem 0 0 lighten($gray, 18%);
                }
            }
        }

        &:disabled,
        &.-is-disabled {
            background-color: $color;
        }
    }
}

@mixin buttonThemes {
    --border-color: darken($olive, 10%);

    border-color: var(--border-color);

    &.-gold {
        --border-color: lighten(#db7908, 10%);

        @include buttonColor(#db7908, #e29339, #e29339, false);

        &:focus {
            @include focusStyles(#db7908);
        }

        &.-is-active {
            background-color: #e29339;
        }
    }

    &.-dark {
        --border-color: lighten($darkestGray, 10%);

        @include buttonColor($darkestGray, $darkGray, $darkGray, false);

        &:focus {
            @include focusStyles($darkestGray);
        }

        &.-is-active {
            background-color: $darkGray;
        }
    }

    &.-light {
        --icon-color: #{$baseColor};
        --border-color: darken($lighterGray, 10%);

        @include buttonColor(
            $lighterGray,
            lighten($lighterGray, 8%),
            darken($lighterGray, 8%),
            false,
        );

        color: $baseColor;

        &:focus {
            @include focusStyles($lighterGray);
        }

        &.-is-active {
            background-color: darken($lighterGray, 8%);
        }
    }

    &.-light-border {
        --border-color: $lighterGray;

        @include buttonColor(
            $lighterGray,
            lighten($lighterGray, 10%),
            lighten($lighterGray, 10%),
            false,
        );

        border: .2rem solid;
        color: $baseColor;

        &.-small {
            padding: calc(#{$buttonSmallPaddingVertical} - .2rem) calc(#{$buttonSmallPaddingHorizontal} - .4rem);
        }

        &:focus {
            @include focusStyles($lighterGray);
        }

        &:hover {
            &:not(:disabled),
            &:not(.-is-disabled) {
                border-color: $lightGray;
            }
        }

        &.-is-active {
            background-color: lighten($lighterGray, 10%);
        }
    }

    &.-success {
        --border-color: lighten($success, 10%);

        @include buttonColor(
            $success,
            lighten($success, 5%),
            darken($success, 5%),
            false,
        );

        &:focus {
            @include focusStyles($success);
        }

        &.-is-active {
            background-color: darken($success, 5%);
        }
    }

    &.-warn {
        --border-color: lighten($warning, 10%);

        @include buttonColor(
            $warning,
            lighten($warning, 5%),
            darken($warning, 5%),
            false,
        );

        color: $baseColor;

        &:focus {
            @include focusStyles($warning);
        }

        &.-is-active {
            background-color: darken($warning, 5%);
        }
    }

    &.-info {
        --border-color: lighten($info, 10%);

        @include buttonColor(
            $info,
            lighten($info, 5%),
            darken($info, 5%),
            false,
        );

        &:focus {
            @include focusStyles($info);
        }

        &.-is-active {
            background-color: darken($info, 5%);
        }
    }

    &.-error {
        --border-color: lighten($error, 10%);

        @include buttonColor(
            $error,
            lighten($error, 10%),
            darken($error, 10%),
            false,
        );

        &:focus {
            @include focusStyles($error);
        }

        &.-active {
            background-color: darken($error, 10%);
        }
    }

    &.-transparent {
        --border-color: transparent;

        @include buttonColor(transparent, transparent, transparent, false);

        &:focus {
            box-shadow: none;
        }

        color: $baseColor;
    }

    &.-link {
        color: $linkColor;

        &:hover {
            color: $linkColorHover;

            &:not(:disabled),
            &:not(.-is-disabled) {
                text-decoration: underline;
            }
        }
    }
}

.tv-grid {
    @include gridBackground;

    padding: 4.6rem 0 2.5rem;

    &.-condensed {
        padding-top: 0;
    }

    &__inner {
        margin: 0 auto;
        max-width: 48rem;
    }

    &__card {
        margin-bottom: 4.5rem;

        .-condensed & {
            margin-bottom: 2.3rem;
        }
    }

    &__seasons-inner {
        border-bottom: $grayBorder;
        display: flex;
        justify-content: space-between;
        margin-bottom: 3.2rem;
        padding-bottom: 1.2rem;
    }

    &__heading {
        color: $white;
        font-size: 1.4rem;
        line-height: 1em;
    }

    &__select {
        appearance: none;
        background-color: transparent;
        border: none;
        color: $white;
        font-size: 1.4rem;
        font-weight: $boldFontWeight;
        padding-right: 2.8rem;
        width: 100%;

        &:focus {
            outline: none;
        }
    }

    &__select-wrapper {
        position: relative;

        &::after {
            @include absoluteCenter(vertical);
            @include icon($iconDropdown, 2.2rem);

            color: $white;
            pointer-events: none;
            right: 0;
        }

        &:focus-within {
            outline: 1px solid $gold;
        }
    }

    &__buttons,
    &__selection {
        display: none;
    }
}

@include tablet {
    .tv-grid {
        padding: 6.8rem 0 7rem;

        &__inner {
            display: grid;
            grid-gap: 4.7rem;
            grid-template: auto / 1fr 1fr;
            max-width: $maxWidth;
        }

        &__card {
            margin: 0;

            .-condensed & {
                margin-bottom: 0;
            }
        }
    }
}

@include desktop {
    .tv-grid {
        $scope: &;

        padding: 7.9rem 0 7.3rem;

        &.-condensed {
            #{$scope} {
                &__select-wrapper,
                &__heading {
                    display: block;
                }

                &__selection,
                &__buttons {
                    display: none;
                }

                &__seasons {
                    margin-bottom: 3.2rem;
                    overflow: initial;
                }

                &__seasons-inner {
                    border-bottom: $darkerGrayBorder;
                    display: flex;
                    padding-bottom: 1.2rem;
                }

                &__card {
                    margin-bottom: 2.3rem;
                }

                &__inner {
                    display: block;
                }
            }
        }

        &__seasons {
            margin-bottom: 5.5rem;
            overflow-x: scroll;
        }

        &__seasons-inner {
            border: none;
            display: block;
            margin-bottom: 0;
            overflow-x: visible;
            padding-bottom: 0;
            position: relative;
        }

        &__buttons {
            display: flex;
            white-space: nowrap;
        }

        &__select-wrapper,
        &__heading {
            display: none;
        }

        &__selection {
            @include selection(bottom, 7rem);
        }

        &__button-wrapper {
            border-bottom: $darkerGrayBorder;
            padding-bottom: 3.1rem;
            padding-right: 4.3rem;
        }

        &__button {
            @include buttonReset;

            color: $lightGray;
            font-size: 1.6rem;
            font-weight: $normalFontWeight;
            transition: color $defaultDuration;

            &.-is-active {
                color: $white;
                font-weight: $boldFontWeight;
            }

            &:hover,
            &:focus {
                color: $white;
                outline: none;
            }
        }

        &__inner {
            display: grid;
            grid-template-columns: repeat(4, 1fr);
        }
    }
}
